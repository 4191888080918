.sidebar-items {
  margin-top: 50px;
}

.ui.visible.right.overlay.sidebar {
  padding-right: 0px;
}

.ui.inverted.menu {
  background: #ffffff;
}

a:hover {
  color: #1e70bf;
  text-decoration: none;
}

.ui.sidebar.menu .item:hover {
  background-color: #39a0ed;
}

.sub-item-display {
  margin-left: 10px;
}

.sub-item {
  display: none;
}

h3 {
  white-space: nowrap;
  padding-top: 12px;
  font-family: cursive;
  font-size: 26px;
  text-shadow: -1px 2px 3px #707f00;
  color: rgb(5, 0, 147);
}

.ui.fullscreen.small.image {
  left: 15px;
  top: 10px;
}

a.ui.hamburger.small.image {
  left: 15px;
  top: 8px;
}

img.logo {
  cursor: pointer;
  height: 100px;
  width: 150px;
}

div.hamburger.right.menu {
  padding-right: 15px;
  top: 8px;
}

div.hamburger.right.menu > img {
  height: 35px;
  cursor: pointer;
}

img.cancel {
  height: 35px;
  float: right;
  cursor: pointer;
}

div.ui.large.menu {
  height: 90px;
  background-color: #ffffff;
}

div.ui.primary.button {
  background: #fec200;
  border: 1px groove #4b4b4b;
  color: #4b4b4b;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

div.ui.primary.button:hover {
  background: red;
}

.ui.menu .item:before {
  background: #ffffff;
}

div.right.menu {
  padding-right: 50px;
}

.ui.image img,
.ui.image svg {
  display: block;
  max-width: 100%;
  height: 80%;
}

.hamburger-icon {
  margin-top: 15px;
}

.ui.tiny.menu {
  background: #ffffff;
  margin-bottom: 0px;
}

.pusher.dimmed:after {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
}

.fixed,
.pusher:after,
.pushable:not(body) > .ui.sidebar {
  position: absolute;
}

.pusher:after {
  position: fixed;
  top: 0;
  right: 0;
  content: '';
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  will-change: opacity;
  z-index: 100;
}

.item.item1 {
  cursor: pointer;
}

.item.item2 {
  cursor: pointer;
}

.item.item3 {
  cursor: pointer;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item > a:not(.ui) {
  background: 0 0;
  color: rgba(0, 0, 0, 0.87);
}

.ui.inverted.menu {
  background: #f2f2f2;
}

@media only screen and (min-width: 1000px) {
  div.ui.tiny.menu {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  div.ui.large.menu {
    display: none;
  }
}

.ui.massive.action.left.icon.input.search-container {
  top: 0;
  font-size: 14px;
  width: auto;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  margin-left:-10px;
}

@media only screen and (max-width: 930px) {
  .search-container {
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 490px) {
  .search-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 416px) {
  .search-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

.background-pic {
  min-width: 100%;
  height: 540px;
  background: linear-gradient(
      0deg,
      rgba(15, 99, 160, 0.3),
      rgba(15, 90, 143, 0.3)
    ),
    url(/static/media/boat-sm.71e8862b.jpg),url(/static/media/boat.a9c49e10.jpg);
  background-size: cover 200px;
  background-position: center right;
  background-repeat: no-repeat no-repeat;
}

.get-your-boat-container {
  text-align: center;
  max-width: 1140px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #fff;
}

.get-your-boat-bold-text {
  display: inline-block;
  margin-top: 40px !important;
  font-weight:600;
  line-height: 40px;
  color: #ffffff;
  -webkit-text-stroke: 1px #f8f8f8;
	text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}

.get-your-boat-intro-text {
  display: inline-block;
  height: 68px;
  top: 120px;
  margin-left:5%;
  font-size: large;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  color: white;
  text-align: center;
	text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}



.ui.primary.button.explore-boats {
  background-color: #fec200;
  box-sizing: border-box;
  color: #4b4b4b;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  cursor: pointer;
  transform: rotate(-2deg);
  margin-top: 10px;
  height: 60px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ui.primary.button.explore-boats:after {
  content: '';
  position: absolute;
  border: 2px groove #999999;
  border-radius: 5px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  bottom: 2px;
  left: 2px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

@media (min-width: 768px) {
  .ui.primary.button.explore-boats {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

.ui.primary.button.explore-boats:hover {
  background-color: red;
  cursor: pointer;
  color: white;
}

.generate-income {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  height: 109px;
  left: 0px;
  top: 791px;
  background: #f7f7f7;
  padding-top: 22px;
  padding-right: 7px;
}

.generate-income-boat {
  text-align: center;
  width: 200px;
}

.get-your-boat-container-text {
  width: 75%;
  text-align: start;
  display: inline-block;
}

@media only screen and (max-width: 425px) {
  .background-pic {
    height: 550px;
    background-size: cover;
    background: linear-gradient(
        0deg,
        rgba(12, 87, 142, 0.3),
        rgba(12, 87, 142, 0.3)
      ),
      url(/static/media/Rectangle-mobile.c88d58f3.png);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .get-your-boat-container-text {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }

  .get-your-boat-bold-text {
    font-size: 28px;
    margin-top: 70px !important;
    line-height: 45px;
  }

  .get-your-boat-intro-text {
    font-size: 15px;
  }

  .get-your-boat-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .ui.primary.button.explore-boats {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .get-your-boat-bold-text {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .generate-income {
    height: 135px;
    padding-top: 10px;
  }

  .generate-income-boat {
    width: 90px;
  }

  .aboutUs-join-us-button {
    margin-top: 5px;
  }
}

.messaging-banner {
  display: flex;
  width: 60%;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  position: relative;
}

.banner-success {
  background-color: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}

.banner-error {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.banner-info {
  background-color: #e2e3e5;
  color: #383d41;
  border: 1px solid #d6d8db;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.login-background {
  min-width: 100%;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(12, 87, 142, 0.3),
      rgba(12, 87, 142, 0.3)
    ),
    url(/static/media/login2.56773b08.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.ui.grid {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.login-form-container {
  text-align: center;
}

.login-form {
  text-align: center;
  width: 420px;
  height: 570px;
  margin-top: 78px;

  background: #ffffff;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  display: inline-block;
}

.login-form h3 {
  margin-top: 40px;
}

.input-container {
  text-align: center;
  margin-top: 35px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-block;
}

.input-align {
  display: flex;
  text-align: left !important;
  margin-top: 12px;
}

.input-align img {
  margin-left: 10px !important;
}

.input-align input {
  margin-left: 15px;
  border-style: none;
  width: 270px;
  outline: none;
}

.forgot-password-container {
  display: inline-block;
}

.rememberThisDevice {
  padding-top: 14px;
  padding-right: 30px;
  text-align: left;
  letter-spacing: 0.03em;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
  display: inline-block;
}

.forgot-password {
  padding-top: 15px;
  text-align: right;
  letter-spacing: 0.03em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  display: inline-block;
  color: #39a0ed;
}

.separator-line {
  display: flex;
  text-align: center;
  justify-content: center;
}

.first-line {
  padding-right: 15px;
}

.second-line {
  padding-left: 15px;
}

.oauth-container {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.oauth-container button {
  width: 320px;
  height: 49px;
  background: #ffffff;
  justify-content: center;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.oauth-container button .facebook-icon {
  padding-right: 10px;
}

.submit-button {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #39a0ed;
  outline: none;
  cursor: pointer;
}

.signup-text {
  display: inline-block;
  margin-top: 15px;
  color: #39a0ed;
  cursor: pointer;
}

.form-error {
  display: inline-block;
  color: red;
}

@media only screen and (max-width: 450px) {
  .login-form {
    width: 365px;
  }
}

.login-background {
  min-width: 100%;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(12, 87, 142, 0.3),
      rgba(12, 87, 142, 0.3)
    ),
    url(/static/media/login2.c9e8b977.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.ui.grid {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.login-form-container {
  text-align: center;
}

.login-form {
  text-align: center;
  width: 420px;
  height: 650px;
  margin-top: 78px;

  background: #ffffff;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  display: inline-block;
}

.login-form h3 {
  margin-top: 40px;
}

.input-container {
  text-align: center;
  margin-top: 35px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-block;
}

input {
  -webkit-appearance: none;
  box-shadow: none !important;
}
:-webkit-autofill {
  color: #fff !important;
}

.input-align {
  display: flex;
  text-align: left !important;
  margin-top: 14px;
}

.input-align img {
  margin-left: 10px !important;
}

.input-align input {
  margin-left: 15px;
  border-style: none;
  width: 270px;
  outline: none;
}

.forgot-password-container {
  display: inline-block;
}

.rememberThisDevice {
  padding-top: 14px;
  padding-right: 30px;
  text-align: left;
  letter-spacing: 0.03em;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
  display: inline-block;
}

.forgot-password {
  padding-top: 15px;
  text-align: right;
  letter-spacing: 0.03em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  display: inline-block;
  color: #39a0ed;
}

.separator-line {
  display: flex;
  text-align: center;
  justify-content: center;
}

.first-line {
  padding-right: 15px;
}

.second-line {
  padding-left: 15px;
}

.oauth-container {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.oauth-container button {
  width: 320px;
  height: 49px;
  background: #ffffff;
  justify-content: center;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.oauth-container button .facebook-icon {
  padding-right: 10px;
}

.submit-button {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #39a0ed;
  outline: none;
  cursor: pointer;
}

.signup-text {
  display: inline-block;
  margin-top: 15px;
  color: #39a0ed;
  cursor: pointer;
}

.form-error {
  display: inline-block;
  color: red;
}

@media only screen and (max-width: 450px) {
  .login-form {
    width: 365px;
  }
}

.footer-container {
  margin-top: 53px;
  height: 354px;
  min-width: 100%;
  background: url(/static/media/footerBackground.e795340a.png);
  background-blend-mode: multiply, normal;
  background-size: cover;
  /* background-position: center center; */
  background-repeat: no-repeat;
  text-align: center;
}

.footer-container-align {
  width: 90%;
  display: inline-block;
}

.footer-content {
  width: auto;
  display: flex;
  justify-content: space-between;
}

.footer-content-hireaboat-icon {
  margin-top: 57px;
  width: 185px;
  height: 59.59px;
  text-align: start;
}

.footer-h4-text-style {
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 103%;
  letter-spacing: 0.05em;
  margin-bottom: 22px;
  color: #ffffff;
}

.footer-p-text-style {
  display: inline;
  font-weight: 300;
  font-size: 14px;
  line-height: 103%;
  /* or 14px */
  letter-spacing: 0.05em;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .footer-content {
    display: block;
  }

  .footer-content-hireaboat-icon.company {
    margin-top: 175px;
  }

  .footer-container {
    height: 750px;
  }

  .footer-content-hireaboat-icon.discover {
    margin-top: 95px;
  }

  .footer-content-hireaboat-icon.join-us {
    margin-top: 95px;
  }
}

.addaboat-container {
  text-align: center;
}

.addaboat-align {
  width: 100%;
  text-align: center;
}

.addaboat-sailor {
  width: 90%;
  height: 188px;
  margin-top: 12px;
  background: #005792;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: inline-block;
}

.addaboat-sailor-align {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: auto;
  text-align: center;
}

.addaboat-sailor-align-text {
  text-align: center;
}

.addaboat-sailor-align h1 {
  margin-top: 45px;
  position: absolute;
  margin-left: 64px;
  height: 67px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  color: #ffffff;
}

.addaboat-sailor-align p {
  position: absolute;
  margin-top: 103px;
  margin-left: 64px;
  height: 29px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.addaboat-sailor-boat-image {
  position: absolute;
  margin-top: 41px;
  margin-right: 90px;
  width: 323px;
  height: 106px;
  right: 0;
}

.addaboat-sailor-design {
  background: url(/static/media/design.e4377c6d.svg);
  width: 100%;
  height: 188px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.addaboat-yourboat-text {
  display: inline;
  vertical-align: top;
}

.addaboat-yourboat {
  float: left;
  color: #343434;
  font-weight: 500;
  font-size: 30px;
  margin-top: 17px;
  text-align: justify;
}

.addaboat-boattype {
  height: 41px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  color: #787878;
}

.addaboat-select-boattype-container {
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 80px;
}

.addaboat-select-boattype-container-align {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.addaboat-select-boattype-box {

  display: block;
  margin-left: auto;
  margin-right: auto;

  cursor: pointer;
  position: relative;
  /* margin-top: 20px; */
  padding: 20px 0;
  padding-bottom: 15px;
  width: 130px;
  height: 130px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.blue-background {
  background: #39a0ed;
  box-shadow: 0px 5px 15px rgba(120, 120, 120, 0.5);
  border-radius: 5px;
}

.dark-icon {
  /* margin-top: 40.32px; */
  filter: invert(45%) sepia(45%) saturate(5%) hue-rotate(8deg) brightness(96%)
    contrast(99%);
}

.white-icon {
  /* margin-top: 40.32px; */
  filter: brightness(0) invert(1);
}

.white-text {
  width: 100%;
  text-align: center;
  bottom: 15px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
}

.dark-text {
  width: 100%;
  text-align: center;
  bottom: 15px;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  color: #787878;
}

.addaboat-select-boattype-box img {
  color: #787878;
}

.addaboat-boat-manufacturer-container {
  width: 70%;
  margin-left: 80px;
  margin-top: 15px;
  display: flex;
}

.add-boat-manufacturer {
  height: 41px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  color: #787878;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}

.addaboat-input-align {
  width: 315px;
  height: 38px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.input-manufacturer {
  width: 100%;
  background: #ffffff;
  color: #787878;
  border-style: none;
  outline: none;
  margin-top: 8px;
}

.currency {
  width: 183px;
}

.currency-input {
  background: #ffffff;
  color: #787878;
  width: 100%;
  border-style: none;
  outline: none;
  margin-top: 8px;
}

.daily-booking-price {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.05em;

  color: #787878;
}

.addaboat-separator-line {
  width: 100%;
  text-align: center;
}

.addaboat-separator-line-align {
  width: 90%;
  display: inline-block;
  border: 1px solid #c4c4c4;
}

.addaboat-boat-size {
  float: left;
  color: #343434;
  font-weight: 500;
  font-size: 30px;
  margin-top: 17px;
  text-align: justify;
}

.addaboat-separator-line.boat-photo {
  width: 100%;
  display: flex;
}

.addaboat-separator-line-align-boat-upload-container {
  display: flex;
  text-align: center;
}

.addaboat-separator-line-align-boat-upload {
  width: 280px;
  height: 220px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 13px;
}

.addaboat-separator-line-align-boat-upload-preview {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.addaboat-separator-line-align-boat-upload-button {
  display: none;
}

.addaboat-separator-line-align-boat-upload-icon {
  filter: invert(45%) sepia(45%) saturate(5%) hue-rotate(8deg) brightness(96%)
    contrast(99%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-top: 61px;
}

.addaboat-save-button {
  width: 171px;
  height: 50px;
  background: #fec200;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 40px;
  outline: none;
  cursor: pointer;
  border: 1px solid darkgray;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 8px 10px rgba(0, 0, 0, 0.1);
}

.addaboat-save-button:hover {
  background-color: red;
}

@media only screen and (max-width: 1170px) {
  .addaboat-sailor-boat-image {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .addaboat-select-boattype-box img {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .addaboat-select-boattype-container-align {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 1000px) {
  .addaboat-select-boattype-container-align {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 935px) {
  .addaboat-separator-line.boat-size {
    display: flex;
    justify-content: center;
  }

  .addaboat-separator-line-align.boat-size {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 868px) {
  .addaboat-boat-manufacturer-container-align {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .addaboat-boat-manufacturer-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
  }
  .add-boat-manufacturer {
    width: auto;
  }
}

@media only screen and (max-width: 791px) {
  .addaboat-sailor-align {
    justify-content: center;
  }

  .addaboat-sailor-align h1 {
    padding-top: 30px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 22px;
    line-height: 27px;
  }

  .addaboat-sailor-align p {
    padding-top: 90px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 740px) {
  .addaboat-input-align {
    width: 250px;
  }

  .currency {
    width: 183px;
  }
}

@media only screen and (max-width: 670px) {
  .add-boat-manufacturer {
    width: 250px !important;
    height: 41px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    display: flex;
    color: #787878;
  }
}

@media only screen and (max-width: 585px) {
  .addaboat-select-boattype-container {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 582px) {
  .addaboat-boat-manufacturer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .add-boat-manufacturer-resize {
    width: 250px;
  }

  .addaboat-boat-manufacturer-container.price {
    justify-content: center !important;
  }

  .currency {
    width: 250px;
  }

  .add-boat-manufacturer-price {
    width: 100% !important;
  }
}

@media only screen and (max-width: 556px) {
  .addaboat-separator-line-align-boat-upload {
    width: 100%;
    margin-right: 0;
  }

  .add-boat-cabins-container {
    width: 100%;
  }
  .addaboat-input-align {
    width: 100% !important;
  }

  .addaboat-separator-line.boat-size {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .addaboat-boat-manufacturer-align {
    width: 100%;
  }

  .addaboat-input-align {
    width: 100%;
  }

  .addaboat-separator-line-align.boat-size {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .add-boat-manufacturer {
    width: 198px !important;
  }

  .add-boat-manufacturer.boat-description {
    width: 250px !important;
  }

  .input-manufacturer.boat-description {
    width: 90% !important;
  }
}

@media only screen and (max-width: 450px) {
  .addaboat-separator-line-align {
    text-align: center;
  }

  .addaboat-separator-line-align-boat-upload-container {
    display: inline-block !important;
    margin-top: 5px;
    width: 100%;
  }

  .addaboat-separator-line-align-boat-upload {
    margin-right: 0px;
  }

  .addaboat-separator-line-align-boat-upload-preview-image {
    margin-right: 0px !important;
  }

  .addaboat-save-button-container {
    text-align: center !important;
  }
}

.boat-result-container {
  width: 100%;
  text-align: center;
}

.boat-result-align {
  width: 90%;
  display: inline-block;
}

.boat-result-input-search-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.boat-result-input-search-top-filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.boat-result-input-search-city {
  position: relative;
  width: 442px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-search-city-label {
  position: absolute;

  left: 62px;
  top: 6px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.boat-result-input-search-city-input {
  height: 26px;
  position: absolute;
  outline: none;
  border: none;
  left: 62px;
  top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #343434;
}

.boat-result-input-search-city-pin {
  position: absolute;
  top: 21px;
  left: 12.7px;
}

.boat-result-input-search-from {
  position: relative;
  width: 203px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-search-from-label {
  position: absolute;

  left: 28px;
  top: 6px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.boat-result-input-search-from-input {
  width: 160px;
  height: 26px;
  position: absolute;
  outline: none;
  border: none;
  left: 28px;
  top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #343434;
}

.boat-result-input-search-to {
  position: relative;
  width: 203px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-search-to-label {
  position: absolute;

  left: 28px;
  top: 6px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.boat-result-input-search-to-input {
  width: 160px;
  height: 26px;
  position: absolute;
  outline: none;
  border: none;
  left: 28px;
  top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
}

.boat-result-input-search-boat-type {
  position: relative;
  width: 342px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-search-boat-type-label {
  position: absolute;
  left: 28px;
  top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #787878;
}

.boat-result-input-search-boat-type-input {
  background: #ffffff;
  width: 300px;
  height: 26px;
  position: absolute;
  outline: none;
  border: none;
  left: 21px;
  top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
}

.boat-result-input-search-boat-type-background {
  background: url(/static/media/new_boatTypeBackground.e01f48ea.png);
  width: 100%;
  height: auto;
  margin-top: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 5px; */
  display: table;
  padding: 10px;
  box-sizing: border-box;
}

.boat-result-input-search-boat-type-background-text {
  font-style: normal;
  font-weight: bold;
  font-size: 8vw;
  margin-top: 10px;
  letter-spacing: 0.05em;
  color: whitesmoke;
  text-shadow: 0px 3px 4px #ffea00;
  text-align: center; /* Center the text */
  word-wrap: break-word; /* Ensure long words break to fit within the container */
}

.boat-result-search-result {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.boat-result-search-result-filter-align {
  width: 90%;
  display: inline-block;
  margin-top: 22px;
}

.boat-result-search-result-filter {
  width: 20%;
  min-height: 470px;
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  display: none; /* Hide filters by default */
}

.boat-result-search-result-filter.expanded {
  display: block; /* Show filters when expanded */
}

.boat-result-search-result-filter-mobile {
  width: 23%;
  height: 973px;
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.boat-result-search-result-filter-label {
  display: flex;
  justify-content: space-between;
}

.boat-result-search-result-filter-label-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.05em;

  color: #000000;
}

.boat-result-search-result-filter-label-price-input {
  width: 100%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
}

.boat-result-search-result-boat-list {
  width: 78%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}

.boat-result-search-result-boat-list-card {
  cursor: pointer;
  width: 321px;
  height: 311px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-top: 30px;
}

.boat-result-search-result-boat-list-card-pic {
  width: 321px;
  height: 213px;
}

.boat-result-search-result-boat-list-card-price {
  top: 179px;
  left: 20px;
  position: absolute;
  color: #ffffff;
}

.boat-result-search-result-boat-list-card-price p {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 37px;
  color: #ffffff;
  margin-right: 5px;
}

.boat-result-search-result-boat-list-card-price-tag {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  color: #ffffff;
  margin-right: 5px;
}

.boat-result-search-live-chat {
  width: 100%;
  height: 79px;
  margin-top: 30px;
}

.boat-result-search-live-chat-align {
  float: right;
  width: 210px;
  height: 79px;

  background: #ffffff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px 0px 0px 30px;
}

.boat-result-input-filter-toggle {
  display: inline-block;
  height: 66px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-filter-toggle-button {
  height: 66px;
  width: auto;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  outline: none;
  cursor: pointer;
  color: #39a0ed;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  margin-top:10px;
  padding: 10px 20px;
  border: 1px solid #c4c4c4;
}

.boat-result-input-filter-container {
  width: 100%;
  margin-top: 20px;
  display: none; /* Hide filters by default */
  flex-wrap: wrap;
  justify-content: space-between;
}

.boat-result-input-filter-container.expanded {
  display: flex; /* Show filters when expanded */
}

.boat-result-input-filter-page-container {
  width: 100%;
  text-align: center;
}

.boat-result-input-filter-page-align {
  display: inline-table;
  text-align: center;
  transition: width 2s, height 2s, transform 2s;
}

.boat-result-search-result-filter-mobile {
  transition: width 2s, height 2s, transform 2s;
}

.boat-result-pagination-container {
  margin-top: 25px;
  width: 100%;
  height: 49px;
  text-align: center;
}

.boat-result-pagination-align {
  display: inline-block;
  width: 45%;
}

.boat-result-pagination-page-number {
  width: 50px;
  height: 50px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.boat-result-pagination-page-number.blue {
  width: 50px;
  height: 50px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  background: #39a0ed;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
}

.boat-result-input-search-city {
  position: relative;
}

.react-calender {
  position: absolute;
  width: 300px;
  top: 80px;
  z-index: 10;
}

.react-calender.toggle-off {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .boat-result-input-search-city {
    width: 300px;
  }

  .boat-result-input-search-from {
    width: 150px;
  }

  .boat-result-input-search-to {
    width: 150px;
  }

  .boat-result-input-search-boat-type {
    width: 240px;
  }

  .boat-result-input-search-city-input {
    width: 210px;
  }

  .boat-result-input-search-from-input {
    width: 110px;
  }

  .boat-result-input-search-to-input {
    width: 110px;
  }

  .boat-result-input-search-boat-type-input {
    width: 200px;
  }
}

@media only screen and (max-width: 934px) {
  .boat-result-input-search-container {
    justify-content: space-around;
  }

  .boat-result-input-search-city {
    width: 240px;
  }

  .boat-result-input-search-from {
    width: 240px;
  }

  .boat-result-input-search-to {
    width: 240px;
  }

  .boat-result-input-search-boat-type {
    width: 240px;
  }

  .boat-result-input-search-city-input {
    width: 170px;
  }

  .boat-result-input-search-from-input {
    width: 200px;
  }

  .boat-result-input-search-to-input {
    width: 200px;
  }

  .boat-result-input-search-boat-type-input {
    width: 200px;
  }
}

@media only screen and (max-width: 938px) {
  .boat-result-search-result {
    flex-direction: column;
  }

  .boat-result-search-result-filter {
    width: 100%;
    margin-bottom: 20px;
  }

  .boat-result-search-result-filter.expanded {
    display: block;
    width: 100%;
  }

  .boat-result-search-result-boat-list {
    width: 100%;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 938px) {
  .boat-result-input-filter-toggle {
    display: none;
  }
  .boat-result-search-result-filter-mobile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .boat-result-pagination-align {
    width: 100%;
  }
}

@media only screen and (max-width: 533px) {
  .boat-result-input-search-city {
    width: 100%;
  }

  .boat-result-input-search-from {
    width: 100%;
  }

  .boat-result-input-search-to {
    width: 100%;
  }

  .boat-result-input-search-boat-type {
    width: 100%;
  }

  .boat-result-input-search-city-input {
    width: 70%;
  }

  .boat-result-input-search-from-input {
    width: 70%;
  }

  .boat-result-input-search-to-input {
    width: 70%;
  }

  .boat-result-input-search-boat-type-input {
    width: 70%;
  }

  /* .boat-result-input-search-boat-type-background-text {
    margin-top: 95px;
  } */
}

.boat-result-input-search-sort {
  position: relative;
  width: 342px;
  height: 66px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
}

.boat-result-input-search-sort-label {
  position: absolute;
  left: 28px;
  top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #787878;
}

.boat-result-input-search-sort-select {
  background: #ffffff;
  width: 300px;
  height: 26px;
  position: absolute;
  outline: none;
  border: none;
  left: 21px;
  top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #39a0ed;
}

.select-boat-container {
  width: 100%;
  text-align: center;
  position: relative;
}

.select-boat-image-container {
  width: 100%;
  height:400px;
  display: inline-block;
}

.select-boat-align {
  display: inline-block;
  width: 80%;
}

.select-boat-profile-details {
  display: flex;
  width: 100%;
}

.select-boat-profile-details-pic {
  position: absolute;
  top: 15px;
  width: 115px;
  height: 115px;
  background: grey;
  margin-left: 70px;
  border: 2px solid #fff;
  border-radius: 100px;
}
.select-boat-profile-details-rating-container {
  display: flex;
  justify-content: space-between;
}

.select-boat-profile-details-rating {
  display: block;
}

.select-boat-profile-details-name {
  position: absolute;
  margin-left: -30px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  width: 200px;
}

.select-boat-profile-details-captain {
  position: absolute;
  margin-top: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* text-shadow: 1px 1px 1px #fff; */
  color: #000000;
}

.select-boat-description {
  width: 100%;
  min-height: 100vh;
  margin-top: 110px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.select-boat-description-left {
  width: 90%;
}

.select-boat-description-left-separator-line {
  width: 100%;

  border: 1px solid #c4c4c4;
}

.select-boat-description-specs-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: 7%;
  margin-bottom: 31px;
  margin-top: 29.1px;
}

.select-boat-description-cabin-container {
  text-align: center;
}

.select-boat-description-cabin-container-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;

  /* identical to box height */
}

.select-boat-description-right {
  float: right;

  position: -webkit-sticky;
  position: sticky;
  top: 2vh;
}

.select-boat-description-booking-card-container {
  position: relative;
  width: 100%;
}

.select-boat-description-booking-card {
  width: 100%;
  height: 442px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  margin-top: -80px;
  margin-left: 40px;
}

.select-boat-description-booking-card-title {
  width: 100%;
  height: 53px;
  display: flex;
  text-align: center;
  background: #39a0ed;
}

.select-boat-description-booking-card-price {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  float: left;
  color: #ffffff;
}

.select-boat-description-booking-card-perday {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  letter-spacing: 0.05em;
  float: left;
  color: #ffffff;
}

.select-boat-description-booking-card-dates-container {
  text-align: center;
  width: 100%;
}

.select-boat-description-booking-card-dates-align {
  display: inline-block;
  width: 85%;
}

.select-boat-description-booking-card-dates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.select-boat-description-booking-card-dates-from {
  height: 40px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 45px;
}

.select-boat-description-booking-card-dates-from-input {
  width: 80%;
  height: 26px;
  margin-top: 5px;

  outline: none;
  border: none;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.select-boat-description-booking-card-dates-from-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.react-calender-booking-card {
  position: absolute;
  width: 300px;
  left: 6px;
  top: 90px;

  z-index: 20;
}

.react-calender-booking-card.toggle-off {
  display: none;
}

.select-boat-description-booking-card-dates-label {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  margin-top: 5px;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-days-container {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.select-boat-description-booking-card-days {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  float: left;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-days-number {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  float: right;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-separator-line {
  border: 0.7px solid #c4c4c4;
  width: 100%;
  margin-top: 25px;
}

.select-boat-description-booking-card-total-heading {
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  float: left;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-total-price {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  float: right;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-book-now-btn {
  width: 100%;
  height: 43px;
  margin-top: 26px;
  background: #50c5b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  letter-spacing: 0.05em;
  outline: none;
  cursor: pointer;
}

.select-boat-description-booking-card-message-owner-btn {
  width: 100%;
  height: 43px;
  margin-top: 18px;
  background: #ffffff;
  border: 1px solid #39a0ed;
  box-sizing: border-box;
  border-radius: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  letter-spacing: 0.05em;
  outline: none;
  color: #39a0ed;
}

.select-boat-description-details-container {
  width: 97%;
}

.select-boat-description-details-description {
  float: left;
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-description-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: inline;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-details-description-body {
  width: 100%;
  position: relative;
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-left-separator-line1 {
  border: 1px solid #c4c4c4;
  width: 100%;
  top: 32px;
  position: absolute;
  margin-top: 32px;
}

.select-boat-description-left-separator-last-line {
  border: 1px solid #c4c4c4;
  width: 100%;
  top: px;
  margin-top: 32px;
}

.select-boat-mobile-booking {
  display: inline-block;
  position: absolute;
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 30px;
}

.select-boat-mobile-booking-align {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.select-boat-description-details-captain {
  float: left;
  margin-top: 40px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-reviews-comments {
  float: left;
  margin-top: 60px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-similar-boats {
  float: left;

  width: 100%;
  text-align: left;
}

.boat-result-search-result-boat-list-card-similar {
  width: 32%;
  height: 311px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-top: 30px;
}

.select-boat-message-owner-container {
  width: 780px;
  display: flex;
  min-height: 200px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 5px;
}

.select-boat-message-owner-container-left {
  width: 30%;
  text-align: center;
  position: absolute;

  height: 100%;

  border-right: 1px solid #c4c4c4;
}

.select-boat-message-owner-container-avatar {
  width: 50%;
  margin-top: 53px;
  display: inline-block;
  height: 115px;
  background: white;
  border-radius: 100px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0px !important;
}

.select-boat-message-owner-container-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.05em;

  color: #000000;
}

.select-boat-message-owner-container-city-and-age {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.select-boat-message-owner-container-right {
  width: 70%;
  text-align: center;
  margin-left: 30%;
  padding-top: 33px;
  padding-bottom: 36px;
}

.select-boat-message-owner-enquiry {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-top: 33px;
  color: #39a0ed;
}

.select-boat-message-owner-departure {
  width: 100%;
  height: 97px;
  padding: 20px 48px 21px 48px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
}

.select-boat-message-owner-departure-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.select-boat-message-owner-departure-text-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.select-boat-message-owner-explain-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-message-owner-input-container {
  width: 65%;
  display: inline-block;
}
.select-boat-message-owner-input-name {
  width: 100%;
  height: 40px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  padding: 0 15px;
  margin-top: 17px;
  outline: none;
  color: #787878;
}

.select-boat-message-owner-button-container {
  width: 65%;
  display: inline-block;
  margin-top: 21px;
}

.select-boat-message-owner-button-align {
  display: flex;
  justify-content: space-between;
}

.select-boat-message-owner-cancel {
  width: 150px;
  height: 40px;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 5px;
  color: #39a0ed;
  border: 1px solid #39a0ed;
  box-sizing: border-box;
  cursor: pointer;
}

.select-boat-message-owner-send {
  cursor: pointer;
  outline: none;
  width: 150px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: #39a0ed;
  border-radius: 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .select-boat-description {
    display: inline;
  }

  .select-boat-description-left {
    width: 100%;
  }

  .select-boat-description-right {
    display: none;
  }
  .select-boat-description-details-container {
    width: 100%;
  }

  .select-boat-description-specs-container {
    width: 100%;
    margin-left: 0px;
  }
  .boat-result-search-result-boat-list-card-similar {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .select-boat-profile-details-pic {
    margin-left: 0px;
  }

  .select-boat-profile-details-name {
    margin-left: 130px;
  }

  .select-boat-profile-details-captain {
    margin-left: 130px;
  }

  .select-boat-align {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .select-boat-message-owner-container {
    width: 100%;
  }

  .select-boat-message-owner-container-left {
    display: none;
  }

  .select-boat-message-owner-container-right {
    width: 100%;
    margin-left: 0px;
  }

  .select-boat-message-owner-input-container {
    width: 70%;
  }

  .select-boat-message-owner-button-container {
    width: 70%;
  }

  .select-boat-message-owner-cancel {
    width: 45%;
  }

  .select-boat-message-owner-send {
    width: 45%;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 2;
  }
}

@media only screen and (min-width: 900px) {
  .select-boat-mobile-booking {
    display: none;
  }
}

.addaboat-successful-container {
  width: 100%;
  text-align: center;
}

.addaboat-successful-container-submitted-bar {
  display: inline-block;
  width: 40%;
  height: 52px;
  margin-top: 25px;
  background: lightgray;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  padding: 1px 0 1px 0;
}

.addaboat-successful-container-submitted-bar-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  background: #ffc107;
  padding: 15px 15px 15px 15px;
  letter-spacing: 0.05em;
  color: white;
  border-radius: 5px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.addaboat-successful-container-being-processed-container {
  margin-top: 70px;
}

.addaboat-successful-container-being-processed {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103%;
  /* or 19px */
  letter-spacing: 0.05em;

  color: #000000;
}
.addaboat-successful-container-being-monitor-container {
  margin-top: 70px;

  text-align: center;
}

.addaboat-successful-container-being-monitor {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103%;
  /* or 19px */

  letter-spacing: 0.05em;

  color: #000000;
}

.addaboat-successful-button {
  width: 150px;
  height: 40px;
  margin-top: 70px;
  margin-bottom: 40px;
  background: #ffc107;
  border-radius: 5px;
  border: 1px solid #eee;  
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #ffffff;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.addaboat-successful-button:hover {
  background: red;
}

@media only screen and (max-width: 768px) {
  .addaboat-successful-container-submitted-bar {
    width: 80%;
  }

  .addaboat-successful-container-being-processed-container {
    display: inline-block;
    width: 80%;
  }

  .addaboat-successful-container-being-monitor-container {
    display: inline-block;
    width: 80%;
  }

  .addaboat-successful-button {
    display: inline;
  }
}

.help-container {
  width: 100%;
  text-align: center;
}

.help-how-it-works {
  font-style: normal;
  font-weight: 600;
  font-size: 43px;
  line-height: 64px;
  margin-top: 20px !important;
  color: #39a0ed;
  text-shadow: -1px 2px 3px #707f00;
}

.help-content-container {
  width: 80%;
  display: inline-block;
  margin-top: 20px;
}

.help-content-header {
  width: 100%;
  padding: 0 20%;
  display: flex;
  justify-content: space-between;
}

.help-content-header-rent-a-boat-blue {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #39a0ed;
  cursor: pointer;
}

.help-content-header-rent-a-boat {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  float: right;
  color: #787878;
  cursor: pointer;
}

.help-content-header-line {
  width: 100%;
  border: 1px solid #c4c4c4;
}

.help-content-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: auto;
}

.help-content-boat1 {
  width: 45%;
  height: 266px;
}

.help-content-search-and-filter {
  width: 45%;
}

.help-content-search-and-filter-header {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  text-align: start;
  color: #000000;
}

.help-content-search-and-filter-content {
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #787878;
}

.help-content-more-help {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #343434;
}

.help-content-more-help-contact-us {
  color: #39a0ed;
  cursor: pointer;
}

@media only screen and (max-width: 875px) {
  .help-content-grid {
    display: inline-block;
  }

  .help-content-boat1 {
    width: 100%;
  }

  .help-content-search-and-filter {
    width: auto;
  }

  .help-content-header {
    padding: 0 5%;
  }
}

.aboutUs-container {
  width: 100%;
  text-align: center;
}

.aboutUs-background-pic {
  position: relative;
  width: 100%;
  height: 411px;
  text-align: center;
  background: url(/static/media/paper-boat.2f6b609b.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.aboutUs-background-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 135px;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 57px;
  line-height: 85px;
  color: #ffffff;
  text-shadow: 0px 3px 4px #707f00;
}

.aboutUs-background-welcome {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #39a0ed;
  text-shadow: 0px 3px 4px #707f00;
}

.aboutUs-white-text {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #343434;
}

.aboutUs-grey-background {
  background: #f7f7f7;
  width: 100%;
  padding: 22px 0;
}

.aboutUs-grey-text-header {
  margin-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  /* identical to box height */

  color: #343434;
}

.aboutUs-join-us {
  width: 100%;
  height: 100px;
  display: inline-block;
  background: #39a0ed;
  padding: 0 25%;
}

.aboutUs-join-us-align {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutUs-join-us-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: #ffffff;
}

.aboutUs-join-us-button {
  width: 150px;
  height: 40px;
  background: #fec200;
  border: 1px groove #eee;
  border-radius: 5px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  color:white
}

.aboutUs-join-us-button:hover {
  background: red;
}

.aboutUs-customers-reviews {
  display: inline-block;
  width: 95%;
  margin-top: 30px;
}

.aboutUs-customers-reviews-header {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  margin-left: 20px;
  float: left;
  color: #343434;
}

.aboutUs-customers-reviews-content {
  width: 100%;
  display: flex;
}

.aboutUs-customers-reviews-content-card {
  width: 25%;
  height: 412px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
}

.aboutUs-customers-reviews-content-card-picture {
  width: 132px;
  height: 132px;
  margin-top: 34px;
}

.aboutUs-customers-reviews-content-card-name {
  margin-top: 23px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;

  color: #39a0ed;
}

.aboutUs-customers-reviews-content-card-review-container {
  display: inline-block;
  width: 90%;
  height: 128px;
  text-align: justify;
}

.aboutUs-customers-reviews-content-card-review {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.05em;

  color: #343434;
}

.aboutUs-customers-reviews-content-card-location {
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;

  letter-spacing: 0.05em;

  color: #787878;
}

.aboutUs-line {
  width: 90%;
  margin-top: 95px;
  display: inline-block;
  border: 1px solid #c4c4c4;
}

@media only screen and (max-width: 1043px) {
  .aboutUs-join-us {
    padding: 0 20%;
  }
}

@media only screen and (max-width: 962px) {
  .aboutUs-customers-reviews-content-card {
    height: 450px;
  }
}

@media only screen and (max-width: 867px) {
  .aboutUs-join-us {
    padding: 0 15%;
  }
}

@media only screen and (max-width: 768px) {
  .aboutUs-customers-reviews-content {
    display: inline-block;
  }
  .aboutUs-customers-reviews-content-card {
    width: 100%;
  }
}

@media only screen and (max-width: 734px) {
  .aboutUs-join-us {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 627px) {
  .aboutUs-join-us {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 627px) {
  .aboutUs-join-us-align {
    display: inline-block;
  }
  .aboutUs-join-us {
    padding: 10px 0;
  }
}

.contactUs-container {
  width: 100%;
  text-align: center;
}

.contactUs-container-background {
  position: relative;
  width: 100%;
  height: 411px;
  text-align: center;
  background: url(/static/media/flare.5f0ee79a.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.contactUs-container-background-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 135px;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 57px;
  line-height: 85px;
  color: #ffffff;
  text-shadow: 0px 3px 4px #707f00;
}

.contactUs-header {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  margin-top: 30px;
  margin-bottom: 0;
  color: #39a0ed;
  text-shadow: 0px 3px 4px #707f00;
}

.contactUs-get-in-touch {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #343434;
}

.contactUs-content-container {
  width: 80%;
  margin-top: 60px;
  display: inline-block;
}

.contactUs-content-align {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contactUs-content-form-container {
  width: 40%;
}

.contactUs-content-form {
  width: 100%;
}

.contactUs-content-form-name {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.contactUs-content-textarea {
  width: 100%;
  height: 135px;
  outline: none;
  padding: 10px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.contactUs-content-button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  margin-top: 29px;
  float: left;
  color: #343434;
  background: #fec200;
  border: 1px groove #eee;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.contactUs-content-button:hover {
  background: red;
  color: white;
}

.contactUs-content-location-container {
  width: 40%;
  text-align: justify;
}

.contactUs-content-location-header {
  display: flex;
}

.contactUs-content-location-header-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.contactUs-content-location-address {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #343434;
}

.contactUs-content-location-line {
  border: 1px solid #c4c4c4;
  margin-top: 20px;
  width: 100%;
}

.contactUs-content-location-email {
  display: flex;
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  .contactUs-content-container {
    width: 90%;
  }

  .contactUs-content-align {
    display: inline-block;
  }

  .contactUs-content-form-container {
    width: 100%;
  }

  .contactUs-content-location-container {
    margin-top: 110px;
    width: 100%;
  }
}

.payment-checkout-container {
  width: 100%;
  text-align: center;
}

.payment-checkout-align {
  width: 80%;
  display: inline-block;
}

.payment-checkout-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.payment-checkout-flex-left {
  width: 57.5%;
}

.payment-checkout-flex-right {
  width: 37.5%;
}

.payment-checkout-header {
  width: 100%;
  height: 50px;
  margin-top: 73px;
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.payment-checkout-header-payment {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #39a0ed;
}

.payment-checkout-header-greaterThan-sign {
  background: linear-gradient(
    115deg,
    rgba(255, 255, 255, 0.2) 0.78%,
    rgba(255, 255, 255, 0) 76.1%
  );
  margin: 0;
}

.payment-checkout-header-confirmation {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #787878;
}

.payment-checkout-card-details {
  text-align: center;
  width: 100%;
  min-height: 310px;
  left: 210px;
  top: 243px;
  margin-top: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.payment-checkout-card-details-header {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}

.payment-checkout-card-details-header-payment-information {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 18px 0 0 0 !important;
  color: #343434;
}

.payment-checkout-card-details-header-icons {
  display: flex;
  justify-content: space-between;
  width: 13%;
  margin-top: 18px;
}

.payment-checkout-line {
  margin-top: 13.5px;
  border: 0.7px solid #c4c4c4;
  width: 90%;
  display: inline-block;
}

.react-tel-input {
  display: flex;
}

.flag-dropdown {
  background-color: #ffffff !important;
  border: none !important;
}

.form-control {
  border: none !important;
}

.payment-checkout-card-details-container {
  display: inline-block;
  width: 90%;
  text-align: center;
  margin-top: 20px;
}

.payment-checkout-card-details-phone-number-align {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-checkout-card-details-phone-number {
  height: 38px;

  width: 69%;
  background: #ffffff;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
}

.payment-checkout-card-details-phone-number-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #343434;
}

.payment-checkout-card-details-email {
  outline: none;
  width: 69%;
  height: 35px;
  padding: 0 15px;
  background: #ffffff;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
}

.payment-checkout-card-details-data {
  display: flex;
  padding: 0 9px;
  width: 69%;
  height: 35px;
  background: #ffffff;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
}

.payment-checkout-card-details-data-number {
  border: none;
  width: 65%;
  padding: 0 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  outline: none;
  color: #343434;
}

.payment-checkout-card-details-data-date {
  border: none;
  outline: none;
  width: 15%;
  padding: 0 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #343434;
}

.payment-checkout-card-details-city-container {
  width: 69%;
  display: flex;
}

.payment-checkout-card-details-city {
  outline: none;
  width: 50%;
  height: 35px;
  padding: 0 15px;
  background: #ffffff;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
}

.payment-checkout-card-details-zipcode-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8%;
}

.payment-checkout-card-details-zipcode {
  outline: none;
  width: 50%;
  height: 35px;
  padding: 0 15px;
  background: #ffffff;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
}

.payment-checkout-card-details-button {
  outline: none;
  width: 150px;
  height: 40px;
  float: right;
  background: #39a0ed;
  border-radius: 5px;
  margin-top: 41px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #ffffff;
}

.payment-checkout-boat-preview {
  width: 100%;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  margin-top: 73px;
}

.payment-checkout-boat-preview-boatModel {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0;
}

.payment-checkout-boat-preview-location {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #39a0ed;
  margin: 0;
}

.payment-checkout-boat-preview-dates {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  width: 100%;
  height: 91px;
  margin-top: 16px;
  background: #f6f6f6;
}

.payment-checkout-boat-preview-dates-from-container {
  width: 40%;
  text-align: center;
}

.payment-checkout-boat-preview-dates-from {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #787878;
}

.payment-checkout-boat-preview-dates-from-date {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.payment-checkout-boat-preview-habour {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #343434;
}

.payment-checkout-boat-preview-habour-location {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #787878;
}

.payment-checkout-boat-preview-line {
  width: 100%;
  margin-top: 15px;
  border: 0.7px solid #c4c4c4;
}

.payment-checkout-boat-preview-price-container {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 20px;
  color: #343434;
}

.payment-checkout-boat-preview-price {
  display: flex;
  justify-content: space-between;
}

.payment-checkout-boat-preview-daily-price {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.payment-successful {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 22px !important;
  margin-bottom: 0 !important;
  color: #343434;
}

.payment-successful-icon {
  display: inline-block;
  margin-top: 17px;
}

.payment-successful-takeoff-container {
  width: 95%;
  display: inline-block;
  height: 229px;
  margin-top: 34px;
  background: #f6f6f6;
}

.payment-successful-takeoff-price {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-top: 22px !important;
  margin-bottom: 0 !important;
  color: #39a0ed;
}

.payment-successful-takeoff-flex {
  width: 100%;
  text-align: center;
}

.payment-successful-takeoff-flex-align {
  width: 90%;
  display: inline-block;
  margin-top: 20px;
}

.payment-successful-takeoff-time {
  width: 40%;
  text-align: start;
}

.payment-successful-takeoff-date {
  width: 60%;
  text-align: start;
}

.payment-successful-takeoff-time-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #343434;
}

.payment-successful-takeoff-date-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #787878;
}

.payment-successful-more-info {
  display: inline-block;
  width: 95%;

  margin-top: 17px;
}

.payment-successful-more-info-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-successful-more-info-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #343434;
}

.payment-successful-more-contact {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 150px;
  height: 40px;
  cursor: pointer;
  outline: none;

  background: #39a0ed;
  border-radius: 5px;
  color: #ffffff;
}

.payment-checkout-boat-preview-location-by {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #343434;
  margin: 0;
}

@media only screen and (max-width: 1159px) {
  .payment-checkout-card-details-data-date {
    width: 20%;
  }
}

@media only screen and (max-width: 1159px) {
  .payment-checkout-card-details-data-date {
    width: 25%;
  }
}

@media only screen and (max-width: 900px) {
  .payment-checkout-align {
    width: 90%;
  }

  .payment-checkout-flex {
    display: inline-block;
  }

  .payment-checkout-flex-left {
    width: 100%;
  }
  .payment-checkout-card-details-header-icons {
    width: 25%;
  }
  .payment-checkout-card-details-phone-number-align {
    display: inline-block;
    text-align: start;
    width: 90%;
  }

  .payment-checkout-card-details-phone-number {
    margin-top: 10px;
    width: 100%;
  }

  .payment-checkout-card-details-container {
    width: 100%;
  }

  .payment-checkout-card-details-email {
    margin-top: 10px;
    width: 100%;
  }

  .payment-checkout-card-details {
    min-height: 310px;
  }

  .payment-checkout-card-details-data {
    width: 100%;
    margin-top: 10px;
  }

  .payment-checkout-card-details-data-number {
    width: 61%;
  }

  .payment-checkout-card-details-data-date {
    width: 20%;
  }

  .payment-checkout-card-details-zipcode {
    padding: 0 5px;
  }

  .payment-checkout-card-details-city-container {
    width: 100%;
  }

  .payment-checkout-card-details.message {
    height: 320px !important;
  }

  .payment-checkout-flex-right {
    width: 100%;

    margin-top: 120px;
  }

  .payment-checkout-boat-preview {
    margin-top: 0;
  }

  .payment-checkout-header {
    margin-top: 30px;
  }
}

.captain-profile-edit-container {
  width: 100%;
  text-align: center;
}

.profileNav-container {
  width: 100%;
  text-align: center;
  height: 49px;
  background: #226292;
}

.profileNav-align {
  width: 70%;
  display: inline-block;
  height: 49px;
}

.profileNav-bar-flex {
  display: table;

  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.profileNav-bar-dashboard {
  display: table-cell;
  width: 16.67%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  vertical-align: middle;
  color: #ffffff !important;
}

.profileNav-bar-dashboard-ash {
  display: table-cell;
  width: 16.67%;
  height: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  vertical-align: middle;
  color: #ffffff;
  background: #226292;
}

.profileNav-bar-dashboard:hover {
  background: #39a0ed;
  border: 1px groove yellow;
}

.personal-profile-flex {
  width: 100%;
  margin-top: 27px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
}

.personal-profile-left {
  width: 20%;
  text-align: start;
}

.personal-profile-right {
  width: 70%;
}

.personal-profile-right-container {
  min-height: 500px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.personal-profile-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #343434;
}

.personal-profile-line {
  width: 100%;
  border: 1px solid #c4c4c4;
}

.personal-profile-sub-text {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;
  margin-top: 15px !important;
  color: #343434;
  cursor: pointer;
}

.personal-profile-sub-text-blue {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;
  margin-top: 15px !important;
  color: #343434;
  cursor: pointer;
}

.personal-information-header {
  width: 100%;
  height: 51px;
  background: #f6f6f6;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;
  padding: 0 4%;
  display: table;
  text-align: start;
}

.personal-information-header-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: table-cell;
  vertical-align: middle;
  color: #343434;
}

.personal-information-body {
  padding: 4%;
}

.personal-information-name {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.personal-information-name-firstname {
  width: 100%;
  height: 35px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  outline: none;
}

.personal-information-name-firstname-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #000000;
}

.personal-information-dob {
  margin-top: 28px;
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.personal-information-name-firstname-input {
  width: 100%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0 15px;
  outline: none;
}

.personal-information-phone-number {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.form-control {
  width: 100% !important;
  height: auto !important;
}

.react-tel-input {
  margin-top: 1px;
}

.personal-information-button {
  width: 150px;
  height: 40px;
  margin-top: 28px;
  border: none;
  background: #39a0ed;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.personal-information-phone-number-container {
  text-align: start;
  margin-top: 28px;
}

.personal-information-firstname-container {
  text-align: start;
  margin-top: 28px;
}

.personal-information-firstname-container {
  margin-top: 0;
}

.personal-information-body-pic {
  position: relative;
  width: 240px;
  height: 281px;
  display: table;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.addaboat-separator-line-align-boat-upload-icon-resize {
  width: 100%;
  height: 281px;
}

.boating-qualification-captain-license {
  width: 60%;
  height: 53px;
  background: #d4efec;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(212, 239, 236, 0.5);
  display: table;
}

.boating-qualification-captain-license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: table-cell;
  vertical-align: middle;
  color: #6a9d97;
}

.boating-qualification-captain-flex {
  margin-top: 28px;
  width: 35%;
  display: flex;
  justify-content: space-between;
}

.boating-qualification-captain {
  width: 45%;
  height: 128px;
  display: table;
  text-align: center;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 5px;
  cursor: pointer;
}

.boating-qualification-captain-image {
  filter: invert(47%) sepia(94%) saturate(0%) hue-rotate(222deg) brightness(82%)
    contrast(85%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.boating-qualification-captain-image-text {
  display: table-row-group;
  vertical-align: bottom;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #787878;
}

.boating-qualification-captain-blue {
  width: 45%;
  height: 128px;
  display: table;
  text-align: center;
  background: #f6f6f6;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 5px;
  cursor: pointer;
  background: #39a0ed;
  box-shadow: 0px 5px 15px rgba(57, 160, 237, 0.5);
}

.boating-qualification-captain-image-blue {
  filter: brightness(0) invert(1);
}

.boating-qualification-captain-image-text-white {
  display: table-row-group;
  vertical-align: bottom;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #ffffff;
}

.boat-experience-boat-driven-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #000000;
}

.boat-experience-boat-driven-checkbox {
  margin-top: 16px;
}

.personal-information-body-pic {
  background: #f6f6f6;
}

@media only screen and (max-width: 1080px) {
  .boating-qualification-captain-flex {
    width: 60%;
  }
  .personal-information-phone-number {
    display: inline-block;
  }
  .personal-information-phone-number-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .profileNav-container {
    display: none;
  }
}

@media only screen and (max-width: 637px) {
  .boating-qualification-captain-flex {
    width: 100%;
  }
  .boating-qualification-captain-license {
    width: 100% !important;
  }
  .personal-profile-flex {
    display: inline-block;
  }

  .personal-profile-left {
    width: 100%;
  }

  .personal-profile-right {
    margin-top: 25px;
    width: 100%;
  }
  .personal-information-dob {
    display: inline-block;
    width: 100%;
    margin-top: 0;
  }
  .personal-information-name {
    display: inline-block;
    width: 100%;
  }

  .personal-information-firstname-container {
    width: 100% !important;
    margin-top: 28px;
  }

  .personal-information-firstname-container-text {
    width: 100% !important;
    margin-top: 0;
  }

  .personal-information-body-pic {
    width: 100%;
  }
}

.dashboard-container {
  width: 100%;
  margin-top: 27px;
  padding: 0 5%;
}

.dashboard-orange-container {
  width: 100%;
  height: 114px;

  background: #39a0ed !important;
  border-radius: 5px;
  text-align: center;
  display: table;
}

.dashboard-orange-container-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 103%;
  /* or 21px */
  display: table-cell;
  letter-spacing: 0.05em;
  vertical-align: middle;
  color: #343434 !important;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, 1);
}

.dashboard-ash-container-flex {
  display: flex;
}

.dashboard-ash-container {
  width: 100%;
  height: 244px;
  margin-top: 27px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.dashboard-ash-flex {
  display: flex;
  padding: 1% 5%;
  align-content: center;
  height: 100%;
}

.dashboard-ash-profile-pic-flex {
  width: 100%;
  padding: 4% 0 4% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
}

.dashboard-ash-profile-pic {
  height: 130px;

  width: 130px;
  border-radius: 50%;
}

.dashboard-ash-profile-pic-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.dashboard-ash-profile-sailor {
  width: 70%;
  text-align: start;
  height: 100%;
  display: table;
  border-right: 0.7px solid #c4c4c4;
}

.dashboard-ash-profile-sailor-text {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.05em;
  margin: 0;
  color: #343434;
}

.dashboard-ash-profile-sailor-container {
  display: flex;
  margin-top: 11px;
}

.dashboard-ash-profile-sailor-container-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin: 0;
  color: #787878;
}

.dashboard-ash-profile-sailor-button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: table-row-group;
  vertical-align: bottom;
  color: #ffffff;
  height: 40px;
  width: 150px;
  background: #39a0ed;
  border-radius: 5px;
  outline: none;
  margin-top: 30px;
  cursor: pointer;
}

.dashboard-ash-profile-sailor-member-container {
  height: 100%;
  padding: 5% 5%;
  display: table;
  width: 100%;
}

.dashboard-ash-profile-sailor-member-text-flex {
  display: flex;
  justify-content: space-between;
}

.dashboard-ash-profile-sailor-member-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #343434;
}

.dashboard-ash-profile-sailor-member-text-left {
  width: 50%;
  text-align: start;
}

.dashboard-ash-profile-sailor-member-text-right {
  width: 50%;
  text-align: start;
}

.dashboard-ash-profile-sailor-body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: 27px;
}

.dashboard-ash-profile-sailor-body-boating-experience {
  width: 23%;
}

.dashboard-ash-profile-sailor-body-boating-experience-text {
  display: table-cell;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  vertical-align: middle;
  color: #343434;
}

.dashboard-ash-profile-sailor-body-boating-about {
  width: 48%;
}

.dashboard-ash-profile-sailor-body-boating-experience-about-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.dashboard-ash-profile-sailor-body-boating-boats {
  width: 23%;
  text-align: start;
}

.dashboard-ash-profile-sailor-body-boating-boats-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #343434;
}

.dashboard-ash-profile-sailor-align {
  display: table-cell;

  vertical-align: middle;
}

.dashboard-ash-profile-sailor-member-container-align {
  display: table-cell;

  vertical-align: middle;
}

@media only screen and (max-width: 910px) {
  .dashboard-ash-profile-sailor-align {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-profile-sailor-body {
    display: inline-block;
  }

  .dashboard-ash-profile-sailor-body-boating-experience {
    width: 100%;
  }

  .dashboard-ash-profile-sailor-body-boating-about {
    width: 100%;
    margin-top: 27px;
  }

  .dashboard-ash-profile-sailor-body-boating-boats {
    width: 100%;
    margin-top: 27px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-container-flex {
    display: inline-block;
    width: 100%;
  }

  .dashboard-ash-container {
    width: 100% !important;
    border: 1px solid #c4c4c4 !important;
  }

  .dashboard-ash-profile-sailor {
    border: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-profile-sailor-align {
    padding-left: 8%;
  }
}

.dashboard-container {
  width: 100%;
  margin-top: 27px;
  padding: 0 5%;
}

.dashboard-orange-container {
  width: 100%;
  height: 114px;

  background: rgba(247, 165, 59, 0.3);
  border-radius: 5px;
  text-align: center;
  display: table;
}

.dashboard-orange-container-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 103%;
  /* or 21px */
  display: table-cell;
  letter-spacing: 0.05em;
  vertical-align: middle;
  color: #c78e45;
}

.dashboard-ash-container-flex {
  display: flex;
}

.dashboard-ash-container {
  width: 100%;
  height: 244px;
  margin-top: 27px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.dashboard-ash-flex {
  display: flex;
  padding: 1% 5%;
  align-content: center;
  height: 100%;
}

.dashboard-ash-profile-pic-flex {
  width: 100%;
  padding: 4% 0 4% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 100%;
}

.dashboard-ash-profile-pic {
  height: 130px;

  width: 130px;
  border-radius: 50%;
}

.dashboard-ash-profile-pic-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.dashboard-ash-profile-sailor {
  width: 70%;
  text-align: start;
  height: 100%;
  display: table;
  border-right: 0.7px solid #c4c4c4;
}

.dashboard-ash-profile-sailor-text {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.05em;
  margin: 0;
  color: #343434;
}

.dashboard-ash-profile-sailor-container {
  display: flex;
  margin-top: 11px;
}

.dashboard-ash-profile-sailor-container-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin: 0;
  color: #787878;
}

.dashboard-ash-profile-sailor-button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: table-row-group;
  vertical-align: bottom;
  color: #ffffff;
  height: 40px;
  width: 150px;
  background: #39a0ed;
  border-radius: 5px;
  outline: none;
  margin-top: 30px;
  cursor: pointer;
}

.dashboard-ash-profile-sailor-member-container {
  height: 100%;
  padding: 5% 5%;
  display: table;
  width: 100%;
}

.dashboard-ash-profile-sailor-member-text-flex {
  display: flex;
  justify-content: space-between;
}

.dashboard-ash-profile-sailor-member-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #343434;
}

.dashboard-ash-profile-sailor-member-text-left {
  width: 50%;
  text-align: start;
}

.dashboard-ash-profile-sailor-member-text-right {
  width: 50%;
  text-align: start;
}

.dashboard-ash-profile-sailor-body {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: 27px;
}

.dashboard-ash-profile-sailor-body-boating-experience {
  width: 23%;
}

.dashboard-ash-profile-sailor-body-boating-experience-text {
  display: table-cell;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  vertical-align: middle;
  color: #343434;
}

.dashboard-ash-profile-sailor-body-boating-about {
  width: 48%;
}

.dashboard-ash-profile-sailor-body-boating-experience-about-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.dashboard-ash-profile-sailor-body-boating-boats {
  width: 23%;
  text-align: start;
}

.dashboard-ash-profile-sailor-body-boating-boats-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #343434;
}

.dashboard-ash-profile-sailor-align {
  display: table-cell;

  vertical-align: middle;
}

.dashboard-ash-profile-sailor-member-container-align {
  display: table-cell;

  vertical-align: middle;
}
.personal-information-earnings-header {
  text-align: start;
  display: table;
  height: 46px;
  width: 100%;
  border-bottom: 1px solid #ffffff;
  padding-left: 26px;
}

.personal-information-earnings-header-text {
  display: table-cell;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #ffffff;
}

.personal-information-earnings-body {
  display: table;
  height: 68px;
  width: 100%;
}

.personal-information-earnings-body-last-earning {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  margin: 0;
  color: #ffffff;
}

.personal-information-earnings-body-last-earning-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #ffffff;
}

.personal-profile-right-container.earnings:hover {
  background: #f04141;
}

.earnings-body-amount-withdrawn-flex-withdrawn-number-container {
  width: 70%;
  display: inline-block;
  text-align: center;
  height: 44px;
}

@media only screen and (max-width: 910px) {
  .dashboard-ash-profile-sailor-align {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-profile-sailor-body {
    display: inline-block;
  }

  .dashboard-ash-profile-sailor-body-boating-experience {
    width: 100%;
  }

  .dashboard-ash-profile-sailor-body-boating-about {
    width: 100%;
    margin-top: 27px;
  }

  .dashboard-ash-profile-sailor-body-boating-boats {
    width: 100%;
    margin-top: 27px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-container-flex {
    display: inline-block;
    width: 100%;
  }

  .dashboard-ash-container {
    width: 100% !important;
    border: 1px solid #c4c4c4 !important;
  }

  .dashboard-ash-profile-sailor {
    border: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-ash-profile-sailor-align {
    padding-left: 8%;
  }
}

@media only screen and (max-width: 680px) {
  .earnings-body-past-payments-date.purpose {
    text-align: center;
    width: 40% !important;
  }
  .earnings-body-past-payments-date.date {
    width: 35% !important;
  }
  .earnings-body-past-payments-date {
    padding-left: 10px !important;
    height: 70px !important;
  }

  .earnings-body-past-payments-date.amount {
    padding-right: 10px !important;
    width: 35% !important;
  }

  .earnings-body-past-payments-header {
    height: 70px !important;
  }

  .earnings-body-amount-withdrawn-flex-withdrawn {
    display: table;
    height: 146px;
  }

  .earnings-body-amount-withdrawn-flex-withdrawn-text {
    display: table-row-group;
    vertical-align: top;
    height: 100%;
  }

  .earnings-body-amount-withdrawn-flex-withdrawn-number-container {
    display: table-row-group;
    vertical-align: bottom;
    height: 100%;
  }

  .earnings-body {
    padding: 28px 5% !important;
  }
}

.earnings-container {
  width: 100%;
  text-align: center;
}

.earnings-body {
  padding: 54px 5%;
}

.earnings-body-amount-withdrawn {
  width: 100%;
  min-height: 50px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 15px 0;
}

.earnings-body-amount-withdrawn-flex {
  display: flex;
}

.earnings-body-amount-withdrawn-flex-withdrawn {
  padding: 13px 0;
  width: 50%;
  text-align: center;
}

.earnings-body-amount-withdrawn-flex-withdrawn-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #787878;
}

.earnings-body-amount-withdrawn-flex-withdrawn-number {
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 52px;
  margin: 0;
  color: #343434;
}

.earnings-body-withdraw-button {
  width: 150px;
  height: 40px;
  margin-top: 19px;
  background: #39a0ed;
  border-radius: 5px;
  float: right;
  outline: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.earnings-body-past-payments {
  width: 100%;

  margin-top: 110px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-bottom: none;
  box-sizing: border-box;
  min-height: 55px;
}

.earnings-body-past-payments-header {
  width: 100%;
  height: 55px;
  display: flex;
  border-bottom: 0.7px solid #c4c4c4;
}

.earnings-body-past-payments-date {
  height: 55px;
  width: 25%;
  display: table;
  text-align: start;
  padding-left: 43px;
}

.earnings-body-past-payments-date-text {
  display: table-cell;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  color: #787878;
}

.message-container {
  width: 100%;
  text-align: center;
}

.message-body {
  padding: 28px 5%;
  width: 100%;
}

.message-inbox-body {
  padding: 19px 5%;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}

.message-inbox-body-profile-pic {
  padding-right: 15px;
  width: 75px;
}

.message-inbox-body-username-container {
  width: 30%;
  padding-right: 4%;
  text-align: start;
}

.message-inbox-body-username {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #39a0ed;
}

.message-inbox-body-message-container {
  width: 60%;
  height: 62px;
  display: table;
}

.message-inbox-body-message {
  display: table-cell;
  vertical-align: middle;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: start;
  color: #787878;
}

.conversation-body {
  display: flex;
  width: 100%;
}

.conversation-body-left {
  width: 60%;
  padding: 16px 5%;
  text-align: start;
  padding-right: 0px;
}

.conversation-body-right {
  width: 40%;
  padding: 26px 16px;
}

.conversation-body-received {
  width: 100%;
  background: #f6f6f6;
  min-height: 23px;
  padding: 10px 12px;
  text-align: start;
  margin-top: 20px;
}

.conversation-body-received-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.conversation-body-sent {
  width: 100%;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  min-height: 23px;
  padding: 10px 12px;
  text-align: start;
}

.conversation-body-reply-to {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-top: 52px;
  color: #343434;
}

.conversation-body-reply-input {
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 70px;
  outline: none;
}

.conversation-body-reply-send {
  margin-top: 15px;
  width: 150px;
  height: 40px;
  float: right;
  background: #39a0ed;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.conversation-fixed-messages {
  height: 367px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.conversation-body-trips {
  text-align: start;
}

.conversation-body-trips-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #343434;
}

.conversation-body-name {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin-top: 15px;
  color: #343434;
}

.conversation-body-name-location {
  display: flex;
  margin-top: 10px;
  text-align: start;
}

.conversation-body-name-location-left {
  width: 50%;
}

.conversation-body-name-location-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #343434;
}

.conversation-body-name-location-text-right {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #343434;
}

.message-inbox-body-date {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.message-inbox-body-profile-pic-size {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.page-item.active .page-link {
  background-color: #39a0ed;
  border-color: #39a0ed;
}

@media only screen and (max-width: 768px) {
  .conversation-body {
    display: inline-block;
  }

  .conversation-body-left {
    width: 100%;
    padding: 16px 5%;
    text-align: center;
  }

  .conversation-body-right {
    width: 100%;
  }

  .conversation-body-trips {
    text-align: center;
  }

  .conversation-body-reply-send {
    float: none;
  }
}

.bookings-container {
  width: 100%;
  text-align: center;
}

.bookings-container-align {
  padding: 28px 5%;
  width: 100%;
}

.bookings-data-flex {
  position: relative;
  min-height: 100px;
  padding: 10px 5% 30px 5px;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.bookings-data-resize {
  width: 150px;
  margin-top: 20px;
}

.bookings-city {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;

  color: #ffffff;
}

.personal-information-body-bookings-container {
  width: 100%;
  height: 124px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  align-items: center;
}

.personal-information-body-bookings-container-boat {
  width: 100%;
  height: 124px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  align-items: center;
}

.personal-information-body-bookings-boat-image {
  width: 133px;
  height: 103px;
  border-radius: 5px;
}

.personal-information-body-bookings-customer-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: #343434;
}

.personal-information-body-bookings-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #787878;
}

.personal-information-body-bookings-price {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  margin: 0;
  color: #39a0ed;
}

.personal-information-body-bookings-options-dropdown {
  display: inline-block;
  width: 27px;
  height: 30px;
  background: #f6f6f6;
  border: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 4px;
}

.ui.dropdown > .dropdown.icon {
  margin: 9px 0 0 9px;
}

.i.icon {
  margin: 0;
}

.ui.small.basic.scrolling.modal.transition.visible.active {
  display: inline-block !important;
  width: 45%;
  left: 28%;

  top: 30%;
}

@media only screen and (max-width: 850px) {
  .personal-information-body-bookings-container {
    height: 300px;
    display: inline-block;
  }

  .personal-information-body-bookings-boat-image {
    height: 180px;
    width: 100%;
  }

  .personal-information-body-bookings-container-boat {
    height: 330px;
    display: inline-block;
  }

  .ui.small.basic.scrolling.modal.transition.visible.active {
    left: 8%;
    width: 80%;
  }
}

.password-reset-input {
    border-radius: 5px;
    width: 50%;
    border-color: #ccc;
}
.password-reset-button {
    border-radius: 5px;
    width: 35%;
    border-color: #ccc;
}
