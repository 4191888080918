h3 {
  white-space: nowrap;
  padding-top: 12px;
  font-family: cursive;
  font-size: 26px;
  text-shadow: -1px 2px 3px #707f00;
  color: rgb(5, 0, 147);
}

.ui.fullscreen.small.image {
  left: 15px;
  top: 10px;
}

a.ui.hamburger.small.image {
  left: 15px;
  top: 8px;
}

img.logo {
  cursor: pointer;
  height: 100px;
  width: 150px;
}

div.hamburger.right.menu {
  padding-right: 15px;
  top: 8px;
}

div.hamburger.right.menu > img {
  height: 35px;
  cursor: pointer;
}

img.cancel {
  height: 35px;
  float: right;
  cursor: pointer;
}

div.ui.large.menu {
  height: 90px;
  background-color: #ffffff;
}

div.ui.primary.button {
  background: #fec200;
  border: 1px groove #4b4b4b;
  color: #4b4b4b;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

div.ui.primary.button:hover {
  background: red;
}

.ui.menu .item:before {
  background: #ffffff;
}

div.right.menu {
  padding-right: 50px;
}

.ui.image img,
.ui.image svg {
  display: block;
  max-width: 100%;
  height: 80%;
}

.hamburger-icon {
  margin-top: 15px;
}

.ui.tiny.menu {
  background: #ffffff;
  margin-bottom: 0px;
}

.pusher.dimmed:after {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
}

.fixed,
.pusher:after,
.pushable:not(body) > .ui.sidebar {
  position: absolute;
}

.pusher:after {
  position: fixed;
  top: 0;
  right: 0;
  content: '';
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  will-change: opacity;
  z-index: 100;
}

.item.item1 {
  cursor: pointer;
}

.item.item2 {
  cursor: pointer;
}

.item.item3 {
  cursor: pointer;
}

.ui.inverted.menu .item,
.ui.inverted.menu .item > a:not(.ui) {
  background: 0 0;
  color: rgba(0, 0, 0, 0.87);
}

.ui.inverted.menu {
  background: #f2f2f2;
}

@media only screen and (min-width: 1000px) {
  div.ui.tiny.menu {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  div.ui.large.menu {
    display: none;
  }
}
