.bookings-container {
  width: 100%;
  text-align: center;
}

.bookings-container-align {
  padding: 28px 5%;
  width: 100%;
}

.bookings-data-flex {
  position: relative;
  min-height: 100px;
  padding: 10px 5% 30px 5px;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.bookings-data-resize {
  width: 150px;
  margin-top: 20px;
}

.bookings-city {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;

  color: #ffffff;
}
