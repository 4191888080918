.login-background {
  min-width: 100%;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(12, 87, 142, 0.3),
      rgba(12, 87, 142, 0.3)
    ),
    url(./Images/login2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.ui.grid {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
}

.login-form-container {
  text-align: center;
}

.login-form {
  text-align: center;
  width: 420px;
  height: 650px;
  margin-top: 78px;

  background: #ffffff;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  display: inline-block;
}

.login-form h3 {
  margin-top: 40px;
}

.input-container {
  text-align: center;
  margin-top: 35px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  display: inline-block;
}

input {
  -webkit-appearance: none;
  box-shadow: none !important;
}
:-webkit-autofill {
  color: #fff !important;
}

.input-align {
  display: flex;
  text-align: left !important;
  margin-top: 14px;
}

.input-align img {
  margin-left: 10px !important;
}

.input-align input {
  margin-left: 15px;
  border-style: none;
  width: 270px;
  outline: none;
}

.forgot-password-container {
  display: inline-block;
}

.rememberThisDevice {
  padding-top: 14px;
  padding-right: 30px;
  text-align: left;
  letter-spacing: 0.03em;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
  display: inline-block;
}

.forgot-password {
  padding-top: 15px;
  text-align: right;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  display: inline-block;
  color: #39a0ed;
}

.separator-line {
  display: flex;
  text-align: center;
  justify-content: center;
}

.first-line {
  padding-right: 15px;
}

.second-line {
  padding-left: 15px;
}

.oauth-container {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.oauth-container button {
  width: 320px;
  height: 49px;
  background: #ffffff;
  justify-content: center;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.oauth-container button .facebook-icon {
  padding-right: 10px;
}

.submit-button {
  display: inline-block;
  margin-top: 15px;
  width: 320px;
  height: 49px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #39a0ed;
  outline: none;
  cursor: pointer;
}

.signup-text {
  display: inline-block;
  margin-top: 15px;
  color: #39a0ed;
  cursor: pointer;
}

.form-error {
  display: inline-block;
  color: red;
}

@media only screen and (max-width: 450px) {
  .login-form {
    width: 365px;
  }
}
