.select-boat-container {
  width: 100%;
  text-align: center;
  position: relative;
}

.select-boat-image-container {
  width: 100%;
  height:400px;
  display: inline-block;
}

.select-boat-align {
  display: inline-block;
  width: 80%;
}

.select-boat-profile-details {
  display: flex;
  width: 100%;
}

.select-boat-profile-details-pic {
  position: absolute;
  top: 15px;
  width: 115px;
  height: 115px;
  background: grey;
  margin-left: 70px;
  border: 2px solid #fff;
  border-radius: 100px;
}
.select-boat-profile-details-rating-container {
  display: flex;
  justify-content: space-between;
}

.select-boat-profile-details-rating {
  display: block;
}

.select-boat-profile-details-name {
  position: absolute;
  margin-left: -30px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  width: 200px;
}

.select-boat-profile-details-captain {
  position: absolute;
  margin-top: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* text-shadow: 1px 1px 1px #fff; */
  color: #000000;
}

.select-boat-description {
  width: 100%;
  min-height: 100vh;
  margin-top: 110px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.select-boat-description-left {
  width: 90%;
}

.select-boat-description-left-separator-line {
  width: 100%;

  border: 1px solid #c4c4c4;
}

.select-boat-description-specs-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: 7%;
  margin-bottom: 31px;
  margin-top: 29.1px;
}

.select-boat-description-cabin-container {
  text-align: center;
}

.select-boat-description-cabin-container-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;

  /* identical to box height */
}

.select-boat-description-right {
  float: right;

  position: -webkit-sticky;
  position: sticky;
  top: 2vh;
}

.select-boat-description-booking-card-container {
  position: relative;
  width: 100%;
}

.select-boat-description-booking-card {
  width: 100%;
  height: 442px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  margin-top: -80px;
  margin-left: 40px;
}

.select-boat-description-booking-card-title {
  width: 100%;
  height: 53px;
  display: flex;
  text-align: center;
  background: #39a0ed;
}

.select-boat-description-booking-card-price {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  float: left;
  color: #ffffff;
}

.select-boat-description-booking-card-perday {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;

  letter-spacing: 0.05em;
  float: left;
  color: #ffffff;
}

.select-boat-description-booking-card-dates-container {
  text-align: center;
  width: 100%;
}

.select-boat-description-booking-card-dates-align {
  display: inline-block;
  width: 85%;
}

.select-boat-description-booking-card-dates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.select-boat-description-booking-card-dates-from {
  height: 40px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  margin-top: 45px;
}

.select-boat-description-booking-card-dates-from-input {
  width: 80%;
  height: 26px;
  margin-top: 5px;

  outline: none;
  border: none;

  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.select-boat-description-booking-card-dates-from-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;

  letter-spacing: 0.05em;

  color: #787878;
}

.react-calender-booking-card {
  position: absolute;
  width: 300px;
  left: 6px;
  top: 90px;

  z-index: 20;
}

.react-calender-booking-card.toggle-off {
  display: none;
}

.select-boat-description-booking-card-dates-label {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  margin-top: 5px;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-days-container {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.select-boat-description-booking-card-days {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  float: left;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-days-number {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  float: right;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-separator-line {
  border: 0.7px solid #c4c4c4;
  width: 100%;
  margin-top: 25px;
}

.select-boat-description-booking-card-total-heading {
  height: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  float: left;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-total-price {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  float: right;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-booking-card-book-now-btn {
  width: 100%;
  height: 43px;
  margin-top: 26px;
  background: #50c5b7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  letter-spacing: 0.05em;
  outline: none;
  cursor: pointer;
}

.select-boat-description-booking-card-message-owner-btn {
  width: 100%;
  height: 43px;
  margin-top: 18px;
  background: #ffffff;
  border: 1px solid #39a0ed;
  box-sizing: border-box;
  border-radius: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  letter-spacing: 0.05em;
  outline: none;
  color: #39a0ed;
}

.select-boat-description-details-container {
  width: 97%;
}

.select-boat-description-details-description {
  float: left;
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-description-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: inline;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-details-description-body {
  width: 100%;
  position: relative;
  margin-top: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-description-left-separator-line1 {
  border: 1px solid #c4c4c4;
  width: 100%;
  top: 32px;
  position: absolute;
  margin-top: 32px;
}

.select-boat-description-left-separator-last-line {
  border: 1px solid #c4c4c4;
  width: 100%;
  top: px;
  margin-top: 32px;
}

.select-boat-mobile-booking {
  display: inline-block;
  position: absolute;
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 30px;
}

.select-boat-mobile-booking-align {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.select-boat-description-details-captain {
  float: left;
  margin-top: 40px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-reviews-comments {
  float: left;
  margin-top: 60px;
  width: 100%;
  text-align: left;
}

.select-boat-description-details-similar-boats {
  float: left;

  width: 100%;
  text-align: left;
}

.boat-result-search-result-boat-list-card-similar {
  width: 32%;
  height: 311px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-top: 30px;
}

.select-boat-message-owner-container {
  width: 780px;
  display: flex;
  min-height: 200px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 5px;
}

.select-boat-message-owner-container-left {
  width: 30%;
  text-align: center;
  position: absolute;

  height: 100%;

  border-right: 1px solid #c4c4c4;
}

.select-boat-message-owner-container-avatar {
  width: 50%;
  margin-top: 53px;
  display: inline-block;
  height: 115px;
  background: white;
  border-radius: 100px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  padding: 0px !important;
}

.select-boat-message-owner-container-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.05em;

  color: #000000;
}

.select-boat-message-owner-container-city-and-age {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #000000;
}

.select-boat-message-owner-container-right {
  width: 70%;
  text-align: center;
  margin-left: 30%;
  padding-top: 33px;
  padding-bottom: 36px;
}

.select-boat-message-owner-enquiry {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-top: 33px;
  color: #39a0ed;
}

.select-boat-message-owner-departure {
  width: 100%;
  height: 97px;
  padding: 20px 48px 21px 48px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
}

.select-boat-message-owner-departure-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.select-boat-message-owner-departure-text-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  color: #787878;
}

.select-boat-message-owner-explain-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: 0.05em;

  color: #343434;
}

.select-boat-message-owner-input-container {
  width: 65%;
  display: inline-block;
}
.select-boat-message-owner-input-name {
  width: 100%;
  height: 40px;

  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  padding: 0 15px;
  margin-top: 17px;
  outline: none;
  color: #787878;
}

.select-boat-message-owner-button-container {
  width: 65%;
  display: inline-block;
  margin-top: 21px;
}

.select-boat-message-owner-button-align {
  display: flex;
  justify-content: space-between;
}

.select-boat-message-owner-cancel {
  width: 150px;
  height: 40px;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 5px;
  color: #39a0ed;
  border: 1px solid #39a0ed;
  box-sizing: border-box;
  cursor: pointer;
}

.select-boat-message-owner-send {
  cursor: pointer;
  outline: none;
  width: 150px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: #39a0ed;
  border-radius: 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .select-boat-description {
    display: inline;
  }

  .select-boat-description-left {
    width: 100%;
  }

  .select-boat-description-right {
    display: none;
  }
  .select-boat-description-details-container {
    width: 100%;
  }

  .select-boat-description-specs-container {
    width: 100%;
    margin-left: 0px;
  }
  .boat-result-search-result-boat-list-card-similar {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .select-boat-profile-details-pic {
    margin-left: 0px;
  }

  .select-boat-profile-details-name {
    margin-left: 130px;
  }

  .select-boat-profile-details-captain {
    margin-left: 130px;
  }

  .select-boat-align {
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .select-boat-message-owner-container {
    width: 100%;
  }

  .select-boat-message-owner-container-left {
    display: none;
  }

  .select-boat-message-owner-container-right {
    width: 100%;
    margin-left: 0px;
  }

  .select-boat-message-owner-input-container {
    width: 70%;
  }

  .select-boat-message-owner-button-container {
    width: 70%;
  }

  .select-boat-message-owner-cancel {
    width: 45%;
  }

  .select-boat-message-owner-send {
    width: 45%;
  }

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 2;
  }
}

@media only screen and (min-width: 900px) {
  .select-boat-mobile-booking {
    display: none;
  }
}
