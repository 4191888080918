.ui.massive.action.left.icon.input.search-container {
  top: 0;
  font-size: 14px;
  width: auto;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  margin-left:-10px;
}

@media only screen and (max-width: 930px) {
  .search-container {
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 490px) {
  .search-container {
    width: fit-content;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 416px) {
  .search-container {
    width: fit-content;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  }
}
