.aboutUs-container {
  width: 100%;
  text-align: center;
}

.aboutUs-background-pic {
  position: relative;
  width: 100%;
  height: 411px;
  text-align: center;
  background: url('./Images/paper-boat.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.aboutUs-background-header {
  position: absolute;
  left: 0;
  right: 0;
  top: 135px;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 57px;
  line-height: 85px;
  color: #ffffff;
  text-shadow: 0px 3px 4px #707f00;
}

.aboutUs-background-welcome {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #39a0ed;
  text-shadow: 0px 3px 4px #707f00;
}

.aboutUs-white-text {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;

  color: #343434;
}

.aboutUs-grey-background {
  background: #f7f7f7;
  width: 100%;
  padding: 22px 0;
}

.aboutUs-grey-text-header {
  margin-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  /* identical to box height */

  color: #343434;
}

.aboutUs-join-us {
  width: 100%;
  height: 100px;
  display: inline-block;
  background: #39a0ed;
  padding: 0 25%;
}

.aboutUs-join-us-align {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutUs-join-us-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: #ffffff;
}

.aboutUs-join-us-button {
  width: 150px;
  height: 40px;
  background: #fec200;
  border: 1px groove #eee;
  border-radius: 5px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  color:white
}

.aboutUs-join-us-button:hover {
  background: red;
}

.aboutUs-customers-reviews {
  display: inline-block;
  width: 95%;
  margin-top: 30px;
}

.aboutUs-customers-reviews-header {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  margin-left: 20px;
  float: left;
  color: #343434;
}

.aboutUs-customers-reviews-content {
  width: 100%;
  display: flex;
}

.aboutUs-customers-reviews-content-card {
  width: 25%;
  height: 412px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-sizing: border-box;
}

.aboutUs-customers-reviews-content-card-picture {
  width: 132px;
  height: 132px;
  margin-top: 34px;
}

.aboutUs-customers-reviews-content-card-name {
  margin-top: 23px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;

  color: #39a0ed;
}

.aboutUs-customers-reviews-content-card-review-container {
  display: inline-block;
  width: 90%;
  height: 128px;
  text-align: justify;
}

.aboutUs-customers-reviews-content-card-review {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.05em;

  color: #343434;
}

.aboutUs-customers-reviews-content-card-location {
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;

  letter-spacing: 0.05em;

  color: #787878;
}

.aboutUs-line {
  width: 90%;
  margin-top: 95px;
  display: inline-block;
  border: 1px solid #c4c4c4;
}

@media only screen and (max-width: 1043px) {
  .aboutUs-join-us {
    padding: 0 20%;
  }
}

@media only screen and (max-width: 962px) {
  .aboutUs-customers-reviews-content-card {
    height: 450px;
  }
}

@media only screen and (max-width: 867px) {
  .aboutUs-join-us {
    padding: 0 15%;
  }
}

@media only screen and (max-width: 768px) {
  .aboutUs-customers-reviews-content {
    display: inline-block;
  }
  .aboutUs-customers-reviews-content-card {
    width: 100%;
  }
}

@media only screen and (max-width: 734px) {
  .aboutUs-join-us {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 627px) {
  .aboutUs-join-us {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 627px) {
  .aboutUs-join-us-align {
    display: inline-block;
  }
  .aboutUs-join-us {
    padding: 10px 0;
  }
}
