.addaboat-successful-container {
  width: 100%;
  text-align: center;
}

.addaboat-successful-container-submitted-bar {
  display: inline-block;
  width: 40%;
  height: 52px;
  margin-top: 25px;
  background: lightgray;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
  padding: 1px 0 1px 0;
}

.addaboat-successful-container-submitted-bar-text {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  background: #ffc107;
  padding: 15px 15px 15px 15px;
  letter-spacing: 0.05em;
  color: white;
  border-radius: 5px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.addaboat-successful-container-being-processed-container {
  margin-top: 70px;
}

.addaboat-successful-container-being-processed {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103%;
  /* or 19px */
  letter-spacing: 0.05em;

  color: #000000;
}
.addaboat-successful-container-being-monitor-container {
  margin-top: 70px;

  text-align: center;
}

.addaboat-successful-container-being-monitor {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103%;
  /* or 19px */

  letter-spacing: 0.05em;

  color: #000000;
}

.addaboat-successful-button {
  width: 150px;
  height: 40px;
  margin-top: 70px;
  margin-bottom: 40px;
  background: #ffc107;
  border-radius: 5px;
  border: 1px solid #eee;  
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #ffffff;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.addaboat-successful-button:hover {
  background: red;
}

@media only screen and (max-width: 768px) {
  .addaboat-successful-container-submitted-bar {
    width: 80%;
  }

  .addaboat-successful-container-being-processed-container {
    display: inline-block;
    width: 80%;
  }

  .addaboat-successful-container-being-monitor-container {
    display: inline-block;
    width: 80%;
  }

  .addaboat-successful-button {
    display: inline;
  }
}
