.messaging-banner {
  display: flex;
  width: 60%;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  position: relative;
}

.banner-success {
  background-color: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}

.banner-error {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.banner-info {
  background-color: #e2e3e5;
  color: #383d41;
  border: 1px solid #d6d8db;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
