.background-pic {
  min-width: 100%;
  height: 540px;
  background: linear-gradient(
      0deg,
      rgba(15, 99, 160, 0.3),
      rgba(15, 90, 143, 0.3)
    ),
    url(images/boat-sm.jpg),url(images/boat.jpg);
  background-size: cover 200px;
  background-position: center right;
  background-repeat: no-repeat no-repeat;
}

.get-your-boat-container {
  text-align: center;
  max-width: 1140px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #fff;
}

.get-your-boat-bold-text {
  display: inline-block;
  margin-top: 40px !important;
  font-weight:600;
  line-height: 40px;
  color: #ffffff;
  -webkit-text-stroke: 1px #f8f8f8;
	text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}

.get-your-boat-intro-text {
  display: inline-block;
  height: 68px;
  top: 120px;
  margin-left:5%;
  font-size: large;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  color: white;
  text-align: center;
	text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
}



.ui.primary.button.explore-boats {
  background-color: #fec200;
  box-sizing: border-box;
  color: #4b4b4b;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  cursor: pointer;
  transform: rotate(-2deg);
  margin-top: 10px;
  height: 60px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ui.primary.button.explore-boats:after {
  content: '';
  position: absolute;
  border: 2px groove #999999;
  border-radius: 5px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  bottom: 2px;
  left: 2px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

@media (min-width: 768px) {
  .ui.primary.button.explore-boats {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

.ui.primary.button.explore-boats:hover {
  background-color: red;
  cursor: pointer;
  color: white;
}

.generate-income {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  height: 109px;
  left: 0px;
  top: 791px;
  background: #f7f7f7;
  padding-top: 22px;
  padding-right: 7px;
}

.generate-income-boat {
  text-align: center;
  width: 200px;
}

.get-your-boat-container-text {
  width: 75%;
  text-align: start;
  display: inline-block;
}

@media only screen and (max-width: 425px) {
  .background-pic {
    height: 550px;
    background-size: cover;
    background: linear-gradient(
        0deg,
        rgba(12, 87, 142, 0.3),
        rgba(12, 87, 142, 0.3)
      ),
      url(images/Rectangle-mobile.png);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .get-your-boat-container-text {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }

  .get-your-boat-bold-text {
    font-size: 28px;
    margin-top: 70px !important;
    line-height: 45px;
  }

  .get-your-boat-intro-text {
    font-size: 15px;
  }

  .get-your-boat-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .ui.primary.button.explore-boats {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .get-your-boat-bold-text {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .generate-income {
    height: 135px;
    padding-top: 10px;
  }

  .generate-income-boat {
    width: 90px;
  }

  .aboutUs-join-us-button {
    margin-top: 5px;
  }
}
