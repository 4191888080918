.sidebar-items {
  margin-top: 50px;
}

.ui.visible.right.overlay.sidebar {
  padding-right: 0px;
}

.ui.inverted.menu {
  background: #ffffff;
}

a:hover {
  color: #1e70bf;
  text-decoration: none;
}

.ui.sidebar.menu .item:hover {
  background-color: #39a0ed;
}

.sub-item-display {
  margin-left: 10px;
}

.sub-item {
  display: none;
}
