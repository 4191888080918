.personal-information-body-bookings-container {
  width: 100%;
  height: 124px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  align-items: center;
}

.personal-information-body-bookings-container-boat {
  width: 100%;
  height: 124px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 15px 2%;
  align-items: center;
}

.personal-information-body-bookings-boat-image {
  width: 133px;
  height: 103px;
  border-radius: 5px;
}

.personal-information-body-bookings-customer-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: #343434;
}

.personal-information-body-bookings-date {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #787878;
}

.personal-information-body-bookings-price {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  margin: 0;
  color: #39a0ed;
}

.personal-information-body-bookings-options-dropdown {
  display: inline-block;
  width: 27px;
  height: 30px;
  background: #f6f6f6;
  border: 1px solid #787878;
  box-sizing: border-box;
  border-radius: 4px;
}

.ui.dropdown > .dropdown.icon {
  margin: 9px 0 0 9px;
}

.i.icon {
  margin: 0;
}

.ui.small.basic.scrolling.modal.transition.visible.active {
  display: inline-block !important;
  width: 45%;
  left: 28%;

  top: 30%;
}

@media only screen and (max-width: 850px) {
  .personal-information-body-bookings-container {
    height: 300px;
    display: inline-block;
  }

  .personal-information-body-bookings-boat-image {
    height: 180px;
    width: 100%;
  }

  .personal-information-body-bookings-container-boat {
    height: 330px;
    display: inline-block;
  }

  .ui.small.basic.scrolling.modal.transition.visible.active {
    left: 8%;
    width: 80%;
  }
}
