.contactUs-container {
  width: 100%;
  text-align: center;
}

.contactUs-container-background {
  position: relative;
  width: 100%;
  height: 411px;
  text-align: center;
  background: url('./images/flare.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.contactUs-container-background-text {
  position: absolute;
  left: 0;
  right: 0;
  top: 135px;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 57px;
  line-height: 85px;
  color: #ffffff;
  text-shadow: 0px 3px 4px #707f00;
}

.contactUs-header {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  margin-top: 30px;
  margin-bottom: 0;
  color: #39a0ed;
  text-shadow: 0px 3px 4px #707f00;
}

.contactUs-get-in-touch {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  color: #343434;
}

.contactUs-content-container {
  width: 80%;
  margin-top: 60px;
  display: inline-block;
}

.contactUs-content-align {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contactUs-content-form-container {
  width: 40%;
}

.contactUs-content-form {
  width: 100%;
}

.contactUs-content-form-name {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.contactUs-content-textarea {
  width: 100%;
  height: 135px;
  outline: none;
  padding: 10px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

.contactUs-content-button {
  width: 150px;
  height: 40px;
  border-radius: 5px;
  margin-top: 29px;
  float: left;
  color: #343434;
  background: #fec200;
  border: 1px groove #eee;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 1);
}

.contactUs-content-button:hover {
  background: red;
  color: white;
}

.contactUs-content-location-container {
  width: 40%;
  text-align: justify;
}

.contactUs-content-location-header {
  display: flex;
}

.contactUs-content-location-header-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.contactUs-content-location-address {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #343434;
}

.contactUs-content-location-line {
  border: 1px solid #c4c4c4;
  margin-top: 20px;
  width: 100%;
}

.contactUs-content-location-email {
  display: flex;
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  .contactUs-content-container {
    width: 90%;
  }

  .contactUs-content-align {
    display: inline-block;
  }

  .contactUs-content-form-container {
    width: 100%;
  }

  .contactUs-content-location-container {
    margin-top: 110px;
    width: 100%;
  }
}
